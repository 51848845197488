// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../shared/components/FaIcon.bs.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ReactUtils from "../../shared/utils/ReactUtils.bs.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Notifications__Root from "../../notifications/Notifications__Root.bs.js";
import * as StudentTopNav__NavLink from "./types/StudentTopNav__Types/StudentTopNav__NavLink.bs.js";
import * as StudentTopNav__DropDown from "./components/StudentTopNav__DropDown.bs.js";
import * as StudentTopNav__UserControls from "./components/StudentTopNav__UserControls.bs.js";

import "./StudentTopNav.css"
;

var partial_arg = "components.StudentTopNav";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function headerLink(key, link) {
  var tmp = {
    className: "whitespace-nowrap no-underline bg-gray-50 md:bg-white hover:bg-gray-50 text-gray-900 rounded-lg hover:text-primary-500 w-full p-4 md:px-3 md:py-2 focus:outline-none focus:bg-gray-50 focus:text-primary-500",
    href: StudentTopNav__NavLink.url(link)
  };
  var tmp$1 = StudentTopNav__NavLink.local(link) ? undefined : "noopener";
  if (tmp$1 !== undefined) {
    tmp.rel = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = StudentTopNav__NavLink.local(link) ? undefined : "_blank";
  if (tmp$2 !== undefined) {
    tmp.target = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", {
              key: key,
              className: "md:ms-2 text-sm font-medium text-center cursor-default flex w-1/2 sm:w-1/3 md:w-auto justify-center border-e border-b md:border-0"
            }, React.createElement("a", tmp, StudentTopNav__NavLink.title(link)));
}

function signOutLink(param) {
  return React.createElement("div", {
              key: "Logout-button",
              className: "md:ms-2 text-sm font-semibold cursor-default flex w-1/2 sm:w-1/3 md:w-auto justify-center border-e border-b md:border-0"
            }, React.createElement("div", {
                  className: "flex items-center justify-center"
                }, React.createElement("a", {
                      className: "border border-primary-500 rounded px-2 py-1 text-primary-500 text-xs md:text-sm md:leading-normal m-4 md:m-0 no-underline font-semibold",
                      href: "/users/sign_out",
                      rel: "nofollow"
                    }, React.createElement(FaIcon.make, {
                          classes: "fas fa-power-off"
                        }), React.createElement("span", {
                          className: "ms-2"
                        }, t(undefined, undefined, "sign_out")))));
}

function signInLink(param) {
  return React.createElement("div", {
              key: "SignIn-button",
              className: "md:ms-2 text-sm font-semibold cursor-default flex w-1/2 sm:w-1/3 md:w-auto justify-center border-e border-b md:border-0"
            }, React.createElement("div", {
                  className: "flex items-center justify-center"
                }, React.createElement("a", {
                      className: "border border-primary-500 rounded px-2 py-1 text-primary-500 text-xs md:text-sm md:leading-normal m-4 md:m-0 no-underline font-semibold",
                      href: "/users/sign_in"
                    }, React.createElement(FaIcon.make, {
                          classes: "fas fa-power-off"
                        }), React.createElement("span", {
                          className: "ms-2"
                        }, t(undefined, undefined, "sign_in")))));
}

function notificationButton(hasNotifications) {
  return React.createElement(Notifications__Root.make, {
              wrapperClasses: "relative md:ms-1 pt-1 md:pt-0 text-sm font-semibold cursor-default flex w-8 h-8 md:w-9 md:h-9 justify-center items-center rounded-lg hover:bg-gray-50",
              buttonClasses: "font-semibold text-gray-900 hover:text-primary-500 w-full flex items-center justify-center focus:text-primary-500 ",
              iconClasses: "student-navbar__notifications-unread-bullet",
              hasNotifications: hasNotifications,
              key: "notifications-button"
            });
}

function isMobile(param) {
  return window.innerWidth < 768;
}

function headerLinks(links, isLoggedIn, user, hasNotifications) {
  var match = $$Array.to_list(links);
  var match$1 = window.innerWidth < 768;
  var match$2;
  if (match$1) {
    match$2 = [
      $$Array.of_list(match),
      []
    ];
  } else if (match) {
    var match$3 = match.tl;
    if (match$3) {
      var match$4 = match$3.tl;
      if (match$4) {
        var match$5 = match$4.tl;
        if (match$5) {
          var match$6 = match$5.tl;
          match$2 = match$6 ? [
              [
                match.hd,
                match$3.hd,
                match$4.hd
              ],
              Js_array.concat($$Array.of_list(match$6.tl), [
                    match$5.hd,
                    match$6.hd
                  ])
            ] : [
              $$Array.of_list(match),
              []
            ];
        } else {
          match$2 = [
            $$Array.of_list(match),
            []
          ];
        }
      } else {
        match$2 = [
          $$Array.of_list(match),
          []
        ];
      }
    } else {
      match$2 = [
        $$Array.of_list(match),
        []
      ];
    }
  } else {
    match$2 = [
      $$Array.of_list(match),
      []
    ];
  }
  var match$7 = window.innerWidth < 768;
  return Js_array.concat([isLoggedIn ? (
                  match$7 ? signOutLink(undefined) : React.createElement(StudentTopNav__UserControls.make, {
                          user: user,
                          key: "user-controls"
                        })
                ) : signInLink(undefined)], Js_array.concat([ReactUtils.nullUnless(notificationButton(hasNotifications), isLoggedIn && window.innerWidth >= 768)], Js_array.concat([React.createElement(StudentTopNav__DropDown.make, {
                            links: match$2[1],
                            key: "more-links"
                          })], Js_array.mapi((function (l, index) {
                            return headerLink(String(index), l);
                          }), match$2[0]))));
}

function StudentTopNav(Props) {
  var schoolName = Props.schoolName;
  var logoUrl = Props.logoUrl;
  var links = Props.links;
  var isLoggedIn = Props.isLoggedIn;
  var currentUser = Props.currentUser;
  var hasNotifications = Props.hasNotifications;
  var match = React.useState(function () {
        return window.innerWidth < 768;
      });
  var toggleMenuHidden = match[1];
  var menuHidden = match[0];
  React.useEffect(function () {
        var resizeCB = function (param) {
          Curry._1(toggleMenuHidden, (function (param) {
                  return window.innerWidth < 768;
                }));
        };
        window.addEventListener("resize", resizeCB);
      });
  return React.createElement("div", {
              className: "border-b"
            }, React.createElement("div", {
                  className: "mx-auto p-4"
                }, React.createElement("nav", {
                      className: "flex justify-between items-center"
                    }, React.createElement("div", {
                          className: "flex w-full items-center justify-between"
                        }, React.createElement("a", {
                              className: "max-w-sm focus:outline-none",
                              href: isLoggedIn ? "/dashboard" : "/"
                            }, logoUrl !== undefined ? React.createElement("img", {
                                    className: "h-8 md:h-10 object-contain flex text-sm items-center",
                                    alt: "Logo of " + schoolName,
                                    src: logoUrl
                                  }) : React.createElement("div", {
                                    className: "p-2 rounded-lg bg-white text-gray-900 hover:bg-gray-50 hover:text-primary-600"
                                  }, React.createElement("span", {
                                        className: "text-xl font-bold leading-tight"
                                      }, schoolName))), ReactUtils.nullUnless(React.createElement("div", {
                                  className: "flex items-center space-x-2"
                                }, ReactUtils.nullUnless(notificationButton(hasNotifications), isLoggedIn), React.createElement("div", {
                                      onClick: (function (param) {
                                          Curry._1(toggleMenuHidden, (function (menuHidden) {
                                                  return !menuHidden;
                                                }));
                                        })
                                    }, React.createElement("div", {
                                          className: "student-navbar__menu-btn cursor-pointer hover:bg-gray-50 w-8 h-8 text-center relative focus:outline-none rounded-lg " + (
                                            menuHidden ? "" : "open"
                                          )
                                        }, React.createElement("span", {
                                              className: "student-navbar__menu-icon"
                                            }, React.createElement("span", {
                                                  className: "student-navbar__menu-icon-bar"
                                                }))))), window.innerWidth < 768)), !menuHidden && window.innerWidth >= 768 ? React.createElement("div", {
                            className: "student-navbar__links-container flex justify-end items-center w-3/5 lg:w-3/4 flex-nowrap shrink-0"
                          }, headerLinks(links, isLoggedIn, currentUser, hasNotifications)) : null)), window.innerWidth < 768 && !menuHidden ? React.createElement("div", {
                    className: "student-navbar__links-container flex flex-row border-t w-full flex-wrap shadow-lg"
                  }, headerLinks(links, isLoggedIn, currentUser, hasNotifications)) : null);
}

var make = StudentTopNav;

export {
  t ,
  str ,
  headerLink ,
  signOutLink ,
  signInLink ,
  notificationButton ,
  isMobile ,
  headerLinks ,
  make ,
}
/*  Not a pure module */
